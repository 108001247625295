<script setup>
import { getCurrentInstance, computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useStore } from '@/store';
import { performBulkDocumentsOperationNotifySign } from '@/app/modules/dashboard/services/bulkOperationsService';
import SConfirmDialog from '@/common/components/SConfirmDialog';

const app = getCurrentInstance();
const route = useRoute();
const vuexStore = useStore();

const props = defineProps({
  value: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(['input', 'refresh-table']);

const showDialog = computed({
  get() {
    return props?.value;
  },
  set(val) {
    emits('input', val);
  },
});
const dashboardCheckedDocuments = computed(() => vuexStore.getters['dashboardCheckedDocuments']);

function onNotifyAction() {
  const docsIds = dashboardCheckedDocuments.value.map((document) => document.docs_id);

  performBulkDocumentsOperationNotifySign(route.params.workspace_id, docsIds)
    .then((resp) => {
      emits('refresh-table', resp?.batchId);
    })
    .catch(() => {
      app.proxy.$notification.error(this.$t('general.save_error'));
    });
}
</script>

<template>
  <SConfirmDialog
    v-model="showDialog"
    :message="`${$tc('docs.bulk_actions.notify_question', dashboardCheckedDocuments.length, { count: dashboardCheckedDocuments.length })}`"
    @confirmation-callback="onNotifyAction"
  />
</template>

<style lang="scss" scoped></style>
