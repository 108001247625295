import CampaignCreationLayout from '@/app/modules/dashboard/layouts/campaignCreationLayout/CampaignCreationLayout.vue';

export const CAMPAIGN_ROUTES = {
  CAMPAIGNS_CREATE_CONTACTS_ROUTE: 'dashboard--campaign-create-contacts',
  CAMPAIGNS_DETAIL_ROUTE: 'dashboard--campaign-detail',
  CAMPAIGNS_ROUTE: 'dashboard--campaigns',
};

export default [
  {
    path: '/dashboard/workspace/:workspaceId/campaigns/:campaignId/create/contacts/envelopId/:envelopId',
    name: CAMPAIGN_ROUTES.CAMPAIGNS_CREATE_CONTACTS_ROUTE,
    component: () => import('@/app/modules/dashboard/features/campaigns/views/CreateCampaignContacts.vue'),
    meta: {
      layout: CampaignCreationLayout,
      fullLayout: true,
    },
  },
  {
    path: '/dashboard/workspace/:workspaceId/campaigns/:campaignId/detail',
    name: CAMPAIGN_ROUTES.CAMPAIGNS_DETAIL_ROUTE,
    component: () => import('@/app/modules/dashboard/features/campaigns/views/CampaignDetail.vue'),
    meta: {
      authRequired: true,
      fullLayout: false,
    },
  },
  {
    path: '/dashboard/workspace/:workspaceId/campaigns',
    name: CAMPAIGN_ROUTES.CAMPAIGNS_ROUTE,
    component: () => import('@/app/modules/dashboard/features/campaigns/views/CampaignsList.vue'),
    meta: {
      authRequired: true,
      fullLayout: false,
    },
  },
];
