import campaignRoutes, { CAMPAIGN_ROUTES } from '@/app/modules/dashboard/features/campaigns/routes';
import superOverviewRoutes, { SUPER_OVERVIEW_ROUTES } from '@/app/modules/dashboard/features/superOverview/routes';

export const DASHBOARD_ROUTES = {
  ...CAMPAIGN_ROUTES,
  ...SUPER_OVERVIEW_ROUTES,
};

const dashboardModuleRoutes = [...campaignRoutes, ...superOverviewRoutes];

export default dashboardModuleRoutes;
