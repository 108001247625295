'use strict';

import { automaticLogout } from '@/middlewares/autoLogout';
import axios from 'axios';

/**
 * The ApiClient class serves as a wrapper around Axios to simplify API requests
 * and ensure they are handled consistently across an application.
 */
export default class ApiClient {
  /**
   * @protected
   */
  client;

  /**
   * Creates an instance of the API client.
   *
   * @param {object} [config] - Optional Axios request configuration.
   */
  constructor(config) {
    this.client = axios.create({
      ...config,
      headers: {
        'Content-Type': 'application/json',
        ...config?.headers,
      },
    });

    this.setupInterceptors();
  }

  /**
   * Sets up Axios request and response interceptors to handle authentication and errors.
   * @private
   */
  setupInterceptors() {
    this.client.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        }

        return config;
      },
      (error) => Promise.reject(error),
    );

    this.client.interceptors.response.use(
      (response) => {
        // TODO: Returning the full response is needed
        return response.data;
      },
      async (error) => {
        const originalRequest = error.config;

        if (401 === error.response?.status && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            await this.refreshToken();

            return this.client.request(originalRequest);
          } catch (err) {
            return Promise.reject(err);
          }
        }

        return Promise.reject({
          message: error.message,
          response: error.response ? error.response.data : undefined,
          status: error.response ? error.response.status : undefined,
        });
      },
    );
  }

  async refreshToken() {
    try {
      const response = await this.client.post(
        '/api/v1/refresh-token',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
          },
        },
      );

      localStorage.setItem('token', response.data?.accessToken);
      localStorage.setItem('refreshToken', response.data?.refreshToken);
    } catch (error) {
      await automaticLogout();
    }
  }

  /**
   * Performs a GET request.
   *
   * @param {string} url - The URL to request.
   * @param {object} [config] - Optional Axios request configuration.
   * @returns {Promise} The response data.
   */
  async get(url, config) {
    return this.client.get(url, config);
  }

  /**
   * Performs a POST request.
   *
   * @param {string} url - The URL to request.
   * @param {object} payload - The payload to send with the request.
   * @param {object} [config] - Optional Axios request configuration.
   * @returns {Promise} The response data.
   */
  async post(url, payload, config) {
    return this.client.post(url, payload, config);
  }

  /**
   * Performs a PUT request.
   *
   * @param {string} url - The URL to request.
   * @param {object} payload - The payload to send with the request.
   * @param {object} [config] - Optional Axios request configuration.
   * @returns {Promise} The response data.
   */
  async put(url, payload, config) {
    return this.client.put(url, payload, config);
  }

  /**
   * Performs a PATCH request.
   *
   * @param {string} url - The URL to request.
   * @param {object} payload - The payload to send with the request.
   * @param {object} [config] - Optional Axios request configuration.
   * @returns {Promise} The response data.
   */
  async patch(url, payload, config) {
    return this.client.patch(url, payload, config);
  }

  /**
   * Performs a DELETE request.
   *
   * @param {string} url - The URL to request.
   * @param {object} [config] - Optional Axios request configuration.
   * @returns {Promise} The response data.
   */
  async delete(url, config) {
    return this.client.delete(url, config);
  }
}
