<script setup>
import { computed } from 'vue';
import { DASHBOARD_ROUTES } from '@/app/modules/dashboard/routes';
import { useRoute } from 'vue-router/composables';

const route = useRoute();

const currentStep = computed(() => {
  switch (route.name) {
    case DASHBOARD_ROUTES.CAMPAIGNS_CREATE_CONTACTS_ROUTE:
      return 1;
    case 'contractsCollectionParties':
      return 2;
    case 'createContent':
      return 3;
    default:
      return 1;
  }
});

const progressSteps = computed(() => [
  {
    step: 1,
    complete: currentStep.value > 1,
    titleKey: 'campaigns.create.progress_steps.step2',
  },
  {
    step: 2,
    complete: currentStep.value > 2,
    titleKey: 'campaigns.create.progress_steps.step3',
  },
  {
    step: 3,
    complete: currentStep.value > 3,
    titleKey: 'campaigns.create.progress_steps.step4',
  },
]);
</script>

<template>
  <v-stepper :value="currentStep">
    <v-stepper-header>
      <template v-for="progressStep in progressSteps">
        <v-stepper-step
          :key="progressStep.step"
          :step="progressStep.step"
          :complete="progressStep.complete"
        >
          <template #default>
            <div class="tw-max-w-48 tw-overflow-hidden tw-text-ellipsis tw-text-sm">
              {{ $t(progressStep.titleKey) }}
            </div>
          </template>
        </v-stepper-step>

        <v-divider
          v-if="progressStep.step < progressSteps.length"
          :key="`${progressStep.step}-divider`"
        ></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<style scoped lang="scss"></style>
