<script setup>
import { getCurrentInstance, ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useStore } from '@/store';
import { performBulkDocumentsOperationChangeProposer } from '@/app/modules/dashboard/services/bulkOperationsService';
import { WorkspaceService } from '@/services/WorkspaceService';

const app = getCurrentInstance();
const route = useRoute();
const vuexStore = useStore();

const props = defineProps({
  value: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(['input', 'refresh-table']);

const selectedProposer = ref(null);
const workspaceUsers = ref([]);

const showDialog = computed({
  get() {
    return props?.value;
  },
  set(val) {
    emits('input', val);
  },
});
const dashboardCheckedDocuments = computed(() => vuexStore.getters['dashboardCheckedDocuments']);
const workspaceProposers = computed(() =>
  workspaceUsers.value.filter((user) => user.id && ('signer' === user.rights || 'owner' === user.rights)),
);
const isProposerSelected = computed(() => !selectedProposer.value);

function getFullName(proposer) {
  return `${proposer?.firstname} ${proposer?.lastname}`;
}

function onChangeProposerAction() {
  const docsIds = dashboardCheckedDocuments.value.map((document) => document.docs_id);

  performBulkDocumentsOperationChangeProposer(route.params.workspace_id, docsIds, selectedProposer.value.id)
    .then((resp) => {
      showDialog.value = false;
      emits('refresh-table', resp?.batchId);
    })
    .catch(() => {
      app.proxy.$notification.error(this.$t('general.save_error'));
    });
}

onMounted(async () => {
  workspaceUsers.value = await WorkspaceService.getUsers(route.params.workspace_id);
});
</script>

<template>
  <v-dialog
    v-model="showDialog"
    max-width="500px"
  >
    <v-card
      class="pb-7"
      elevation="0"
    >
      <v-toolbar
        class="pt-1"
        dense
        flat
      >
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title>
        <h2 class="font-weight-medium mx-auto mb-0 pb-3 text-center">
          {{ $t('docs.bulk_actions.change_proposer_title') }}
        </h2>
      </v-card-title>

      <v-card-actions>
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="8">
            <v-select
              v-model="selectedProposer"
              background-color="white"
              :items="workspaceProposers"
              :item-text="getFullName"
              :label="$t('docs.bulk_actions.change_proposer_title')"
              :placeholder="$t('docs.bulk_actions.change_proposer_title')"
              hide-details
              return-object
              outlined
            >
            </v-select>
          </v-col>

          <v-col
            cols="2"
            class="pl-3"
          >
            <v-btn
              class="primary"
              dark
              x-large
              block
              outlined
              :disabled="isProposerSelected"
              @click="onChangeProposerAction"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
