import { apiClient } from '@/app/api';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    data: null,
  }),
  actions: {
    fetchProfile() {
      return apiClient.get(`api/v1/loggeduser`).then((resp) => {
        this.data = Object.assign({}, resp);

        return resp;
      });
    },
    resetProfile() {
      this.data = null;
    },
  },
  getters: {
    accountProviders: (state) => {
      return state.data?.providers;
    },
    isPasswordSet: (state) => {
      return state.data?.isPasswordSet;
    },
    user: (state) => {
      return state.data;
    },
    userEmail: (state) => {
      return state.data?.email;
    },
    userFeatureFlags: (state) => {
      return state.data?.feature_flags;
    },
    userId: (state) => {
      return state.data?.id;
    },
    userInvitations: (state) => {
      return state.data?.invitations;
    },
    userInvoiceInfo: (state) => {
      return state.data?.invoice_info;
    },
    userName: (state) => {
      return `${state.data?.firstname || ''} ${state.data?.lastname || ''}`?.trim();
    },
    userPaymentCard: (state) => {
      return state.data?.payment_card;
    },
    userProfile: (state) => {
      return state.data;
    },
    userProfileCredits: (state) => {
      return state.data?.credits || 0;
    },
    userWorkspaces: (state) => {
      return state.data?.workspaces;
    },
  },
});
