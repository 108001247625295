var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{class:_setup.classify(
      'tw-grid tw-min-h-[63px] tw-grid-cols-3 tw-items-center tw-justify-between tw-px-5',
      'tw-border-b tw-border-b-blue-200 tw-bg-blue-50',
      'md:tw-px-10',
    )},[_c('div'),_c('div',{staticClass:"tw-text-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
          name: 'dashboard',
          params: {
            workspace_id: _vm.$route.params.workspaceId,
          },
        })}}},[_c('v-img',{staticClass:"shrink",attrs:{"src":_setup.signiIcon,"transition":"scale-transition","contain":""}})],1)],1),_c('div',{staticClass:"tw-text-right"},[_c('v-btn',{staticClass:"subtitle-1 font-weight-regular",attrs:{"color":"primary","text":""},on:{"click":_setup.onClose}},[_c('span',{staticClass:"tw-hidden md:tw-block"},[_vm._v(" "+_vm._s(_vm.$t(`general.save_and_close`))+" ")]),_c('v-icon',{attrs:{"color":"#7031B4","size":"24","right":!_vm.$vuetify.breakpoint.mobile}},[_vm._v(" "+_vm._s(`mdi-${_vm.$vuetify.breakpoint.mobile ? 'content-save-outline' : 'close'}`)+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }