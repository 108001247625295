<script setup>
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useUserStore } from '@/stores/user';
import { useWorkspaceStore } from '@/stores/workspace';
import PageLoader from '@/components/PageLoader.vue';
import CampaignCreationHeader from '@/app/modules/dashboard/layouts/campaignCreationLayout/components/CampaignCreationHeader.vue';
import { classify } from 'tailwind-classify';

const route = useRoute();
const userStore = useUserStore();
const workspaceStore = useWorkspaceStore();

const { fetchProfile, resetProfile } = userStore;
const { setWorkspaceSettings, resetWorkspaceSettings } = workspaceStore;

const loaded = ref(true);
const hasLoadingError = ref(false);

const workspaceId = computed(() => route.params.workspaceId);

function reset() {
  resetProfile();
  resetWorkspaceSettings();
}

onBeforeMount(() => reset());
onBeforeUnmount(() => reset());
onMounted(async () => {
  loaded.value = false;
  hasLoadingError.value = false;

  try {
    const userProfileResponse = await fetchProfile();

    setWorkspaceSettings(userProfileResponse?.workspaces?.find((w) => `${workspaceId.value}` === `${w?.id}`));
  } catch (error) {
    hasLoadingError.value = true;
  } finally {
    loaded.value = true;
  }
});
</script>

<template>
  <v-app :class="classify(loaded && 'app-container')">
    <CampaignCreationHeader v-if="loaded" />

    <v-main :class="classify(loaded && 'tw-container tw-mx-auto')">
      <PageLoader v-if="!loaded" />

      <div v-else-if="hasLoadingError">Error loading data</div>

      <slot v-else></slot>
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
.app-container {
  background-color: #fbfbfb;
}
</style>
