<template lang="html">
  <v-container
    class="pa-0 ma-0"
    fluid
  >
    <v-row
      class="pt-1"
      align="center"
      :justify="$vuetify.breakpoint.mobile ? 'space-between' : 'end'"
      style="border-bottom: 1px solid #dfe7f3"
      no-gutters
    >
      <v-col
        cols="auto"
        class="pa-1"
      >
        <v-btn
          v-if="canGoBackToEdit"
          text
          @click="$emit('goBackToEdit')"
        >
          {{ $t('contract.signs.edit_back') }}
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
          text
          color="primary"
          @click="expandBlock = !expandBlock"
        >
          {{ isExpandedBlock ? $t('general.block.collapse') : $t('general.block.expand') }}
          <template v-if="isExpandedBlock">
            <v-icon>mdi-chevron-down</v-icon>
          </template>
          <template v-else>
            <v-icon>mdi-chevron-up</v-icon>
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-expand-transition>
      <div v-show="isExpandedBlock">
        <div :class="[contentClass]">
          <div>
            <div class="contract-bottom-block">
              <div class="contract-bottom-content contract-bottom-content--left">
                <div
                  class="content__buttons--add-attachment"
                  @click="$emit('addAttachment')"
                >
                  <v-row
                    align="center"
                    justify="center"
                    no-gutters
                  >
                    <v-col
                      cols="auto"
                      class="pa-2 content__buttons--add-attachment-icon"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-col>

                    <v-col class="pl-2">
                      <v-row
                        align="start"
                        justify="start"
                        no-gutters
                      >
                        <v-col
                          cols="12"
                          class="text-left"
                        >
                          <span class="subtitle-2 font-weight-bold">
                            {{ $t('contract.attach_document') }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-left"
                        >
                          <span class="subtitle-2 font-weight-regular">
                            {{ $t('contract.attachment_types') }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <div
                v-if="(isCampaignCreation || isCampaign) && isDoneSignaturesPlacing"
                :class="
                  classify(
                    'tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-between tw-gap-4 md:tw-flex-row',
                    'md:tw-justify-end',
                  )
                "
              >
                <!--                <v-btn-->
                <!--                  v-if="isEditingCampaignContract"-->
                <!--                  color="primary"-->
                <!--                  width="100%"-->
                <!--                  max-width="15rem"-->
                <!--                  :loading="isSavingCampaign"-->
                <!--                  :block="$vuetify.breakpoint.mobile"-->
                <!--                  @click="onSaveCampaignContract"-->
                <!--                >-->
                <!--                  {{ $t('general.save') }}-->
                <!--                </v-btn>-->

                <v-btn
                  color="primary"
                  width="100%"
                  max-width="15rem"
                  :loading="isFinishingCampaign"
                  :block="$vuetify.breakpoint.mobile"
                  @click="onFinishCampaign"
                >
                  {{ $t('general.finish') }}
                </v-btn>
              </div>

              <div
                v-else-if="isInSignableState && areContractRolesConsistent && isDoneSignaturesPlacing"
                class="contract-bottom-content contract-bottom-content--right"
                :class="{ 'contract-bottom-content--centered': !isSignableByCreator }"
              >
                <v-row
                  v-if="isSending"
                  align="center"
                  :justify="$vuetify.breakpoint.mobile ? 'center' : 'end'"
                >
                  <v-col cols="auto">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-col>
                </v-row>

                <v-row
                  v-else
                  align="center"
                  justify="end"
                  no-gutters
                >
                  <template v-if="isAmlVerification">
                    <v-col
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendToAmlVerification)"
                      >
                        {{ $t('contract.send_counterparty') }}
                      </v-btn>
                    </v-col>
                  </template>

                  <template v-else-if="isSigningWithCertificate">
                    <v-col
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        min-width="12rem"
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendToSpecialSignProcess)"
                      >
                        {{ $t('general.continue') }}
                      </v-btn>
                    </v-col>
                  </template>

                  <template v-else-if="isSigningWithBankId">
                    <v-col
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        min-width="12rem"
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendToSpecialSignProcess)"
                      >
                        {{ $t('general.continue') }}
                      </v-btn>
                    </v-col>
                  </template>

                  <template v-else-if="isSigningWithHandySignatur">
                    <v-col
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        min-width="12rem"
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendToSpecialSignProcess)"
                      >
                        {{ $t('general.continue') }}
                      </v-btn>
                    </v-col>
                  </template>

                  <template v-else-if="isSigningWithRemoteSign">
                    <v-col
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        min-width="12rem"
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendToSpecialSignProcess)"
                      >
                        {{ $t('general.continue') }}
                      </v-btn>
                    </v-col>
                  </template>

                  <template v-else>
                    <v-col
                      v-if="canSendAutosignedContract"
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendDocumentWithoutSignAsParty)"
                      >
                        {{ $t('contract.send_counterparty') }}
                      </v-btn>
                    </v-col>

                    <v-col
                      v-if="canSendWithoutSign"
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-row
                        align="center"
                        no-gutters
                      >
                        <v-col cols="auto">
                          <s-help :message="$t('info.document.send_without_signature')"></s-help>
                        </v-col>

                        <v-col>
                          <v-btn
                            text
                            x-large
                            :block="$vuetify.breakpoint.mobile"
                            @click="send(sendDocumentWithoutSignAsParty)"
                          >
                            {{ $t('contract.send_without_signature.my') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col
                      v-if="canContinueForSign"
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendDocumentWithSign)"
                      >
                        {{ $t('contract.continue_to_signature') }}
                      </v-btn>
                    </v-col>

                    <v-col
                      v-if="canAgreeWithContract"
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendDocumentWithoutSignAsParty)"
                      >
                        {{ $t('signature.agree_with_contract') }}
                      </v-btn>
                    </v-col>

                    <v-col
                      v-if="canSendToReview"
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendDocumentWithoutSignToReview)"
                      >
                        {{ $t('review.send') }}
                      </v-btn>
                    </v-col>

                    <v-col
                      v-if="canSendToContacts"
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendDocumentWithoutSignToContacts)"
                      >
                        {{ $t('contract.send_to_contacts') }}
                      </v-btn>
                    </v-col>

                    <v-col
                      v-if="canSendAsAssistant"
                      cols="12"
                      md="auto"
                      class="pa-1"
                    >
                      <v-btn
                        color="primary"
                        x-large
                        :block="$vuetify.breakpoint.mobile"
                        @click="send(sendDocumentWithoutSignAsAssistant)"
                      >
                        {{ $t('contract.send_to_signature') }}
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
              </div>

              <div
                class="mr-5"
                v-else-if="!isDoneSignaturesPlacing"
              >
                <v-btn
                  color="primary"
                  x-large
                  @click="redirectToUnfinishedDocument"
                >
                  {{ $t('contract.create.continue_to_next_contract') }}
                </v-btn>
              </div>

              <div
                v-else-if="!areContractRolesConsistent"
                class="contract-bottom-content contract-bottom-content--right contract-bottom-content--inconsistent"
              >
                {{ $t('contract.signs.inconsistent') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-expand-transition>

    <ScheduleCampaignDialog
      v-if="scheduleCampaignRequested"
      :is-scheduling-campaign="isSchedulingCampaign"
      @schedule-campaign="onScheduleCampaign"
      @cancel="scheduleCampaignRequested = false"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ContractHelper } from '@/helpers/ContractHelper';
import { WorkflowHelper } from '@/helpers/WorkflowHelper.js';
import { ContractService } from '@/services/ContractService.js';
import { authorizedApiRequest } from '@/services/ApiService';
import { getErrorMessage, getErrorResponseMessage } from '@/common/reusable/errorResponse.js';
import { useContactsStore } from '@/features/contacts/stores/contacts';
import { useContractSignaturesPlaceholdersStore } from '@/features/contract/stores/contractSignaturesPlaceholdersStore';
import { useTemplateFieldsStore } from '@/features/contract/stores/templateFieldsStore';
import { internalApiRequest } from '@/shared/services/apiService';
import { approveContractAsRegisteredUser } from '@/features/contract/services/contractService';
import featureFlagsChecker from '@/common/reusable/featureFlagsChecker';
import IcoPlus from '@/components/svg/IcoPlus';
import { computed, ref } from 'vue';
import ScheduleCampaignDialog from '@/app/modules/dashboard/features/campaigns/components/ScheduleCampaignDialog.vue';
import { classify } from 'tailwind-classify';
import {
  confirmCampaignSetup,
  fetchCampaignById,
} from '@/app/modules/dashboard/features/campaigns/services/campaignsService';
import { CAMPAIGN_ROUTES } from '@/app/modules/dashboard/features/campaigns/routes';

export default {
  name: 'ContentFinishedFull',
  components: {
    ScheduleCampaignDialog,
    IcoPlus,
  },
  setup() {
    const campaign = ref(null);
    const expandBlock = ref(true);
    const scheduleCampaignRequested = ref(false);
    const isSending = ref(false);
    const isFinishingCampaign = ref(false);
    const isSavingCampaign = ref(false);

    const contactsStore = useContactsStore();
    const contractSignaturesPlaceholdersStore = useContractSignaturesPlaceholdersStore();
    const templateFieldsStore = useTemplateFieldsStore();

    return {
      contactsIdsForContracts: computed(() => contactsStore.contactsIdsForContracts),
      contractsIdsList: computed(() => contractSignaturesPlaceholdersStore.contractsIdsList),
      isAllSignaturesPlaced: computed(() => contractSignaturesPlaceholdersStore.isAllSignaturesPlaced),
      getNextUndoneContractId: computed(() => contractSignaturesPlaceholdersStore.getNextUndoneContractId),
      isTemplateCompleted: computed(() => templateFieldsStore.isTemplateCompleted),
      counterpartyInputsCount: computed(() => templateFieldsStore.counterpartyInputsCount),
      allTemplatesAreCompleted: computed(() => templateFieldsStore.areAllTemplatesCompleted),
      getNextUndoneTemplateId: computed(() => templateFieldsStore.getNextUnfinishedTemplate),
      campaign,
      expandBlock,
      scheduleCampaignRequested,
      isSending,
      isFinishingCampaign,
      isSavingCampaign,
      templateFieldsStore,
      contactsStore,
    };
  },
  props: {
    contentClass: {},
    confirmBeforeSend: {},
    areContractRolesConsistent: {
      type: Boolean,
      default: true,
    },
    contractSignatures: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      contractSignIdentities: 'contractSignIdentities/signIdentities',
      contractCurrentSignIdentity: 'contractSignIdentities/currentSignIdentity',
      profile: 'profile',
    }),
    campaignId() {
      return this.contract?.campaignId;
    },
    isCampaign() {
      return !!this.campaignId;
    },
    isCampaignCreation() {
      return this.$route.query?.campaign;
    },
    isEditingCampaignContract() {
      return 'draft' === this.campaign?.status;
    },
    isDoneSignaturesPlacing() {
      return this.isAllSignaturesPlaced && this.allTemplatesAreCompleted;
    },
    contractDataObject() {
      return Object.assign(this.contract, {
        sign_identities: this.contractSignIdentities,
        current_sign_identity: this.contractCurrentSignIdentity,
      });
    },
    isAmlVerification() {
      const isAmlVerification = this.contract?.has_authentication || false;
      const isAmlVerificationDone = this.contract?.is_verification_done || false;

      return isAmlVerification && !isAmlVerificationDone;
    },
    isSigningWithCertificate() {
      return this.contract?.has_sign_with_certificate || false;
    },
    isSigningWithBankId() {
      return this.contract?.has_sign_with_bank_id_sign || false;
    },
    isSigningWithHandySignatur() {
      return this.contract?.has_sign_with_handy_signatur || false;
    },
    isSigningWithRemoteSign() {
      return this.contract?.has_sign_with_remote_sign || false;
    },
    isExpandedBlock() {
      return this.expandBlock;
    },
    canGoBackToEdit() {
      return this.$listeners && this.$listeners.goBackToEdit;
    },
    isInSignableState() {
      return this.contract.state === 'pending' || this.contract.state === 'draft';
    },
    isSignableByCreator() {
      return ContractHelper.isSignatureRequiredForCurrentUser(this.contractDataObject);
    },
    isSpecialSend() {
      return this.canSendAsAssistant || this.canSendToContacts || this.canSendToReview;
    },
    canSendAsAssistant() {
      return (
        this.contract.current_user &&
        !this.contract.current_user.is_contract_party &&
        !this.canSendToContacts &&
        !this.canSendToReview
      );
    },
    canSendToContacts() {
      return featureFlagsChecker.isEditingContactsTemplate(this.contract) && !this.canSendToReview;
    },
    canSendToReview() {
      return this.contract.comments_usage == 'for_review';
    },
    canSendWithoutSign() {
      // it's confusing, because sendDocumentWithoutSignAsParty is called with different buttons
      // - one-device has canContinueForSign (sign) for normal contracts, canAgreeWithContract (approve) for one-device contracts
      // - autosign has canSendAutosignedContract for normal contracts, canAgreeWithContract for one-device contracts

      return !(this.isSpecialSend || this.contract.one_device || this.isAutosignable);
    },
    canContinueForSign() {
      if (this.isSpecialSend || (this.isAutosignable && !this.contract.one_device)) {
        return false;
      }

      if (!this.isTemplateCompleted && !this.isSignedByCounterparty && this.counterpartyInputsCount) {
        return false;
      }

      return this.isSignableByCreator && ContractHelper.isUnsignedOrUnapproved(this.contractDataObject);
    },
    canSendAutosignedContract() {
      if (this.isSpecialSend || this.contract.one_device || !this.isAutosignable) {
        return false;
      }

      return ContractHelper.isUnsignedOrUnapproved(this.contractDataObject);
    },
    canAgreeWithContract() {
      if (this.isSpecialSend || !this.contract.one_device) {
        return false;
      }

      return !this.isSignableByCreator && ContractHelper.isUnsignedOrUnapproved(this.contractDataObject);
    },
    isAutosignable() {
      return this.contract.auto_signing_enabled && this.profile.has_signature;
    },
    isSignedByCounterparty() {
      return this.contract?.sign_identities?.some(
        (signIdentity) => !signIdentity.is_proposer && signIdentity.is_signed,
      );
    },
    mainContractId() {
      return +(this.contract?.main_contract_id || this.contract.id);
    },
  },
  async mounted() {
    if (this.isCampaign) {
      this.campaign = await fetchCampaignById(this.campaignId);
    }
  },
  methods: {
    classify,
    async onFinishCampaign() {
      this.isFinishingCampaign = true;

      try {
        await confirmCampaignSetup(this.campaignId, [this.mainContractId]);

        void this.$router.push({
          name: CAMPAIGN_ROUTES.CAMPAIGNS_DETAIL_ROUTE,
          params: {
            campaignId: this.campaignId,
            workspaceId: this.$route.params.workspace_id,
          },
        });
      } catch (err) {
        this.$notification.error(this.$t('campaign.error_finishing'));
      } finally {
        this.isFinishingCampaign = false;
      }
    },
    onSaveCampaignContract() {
      this.isSavingCampaign = true;

      void this.$router.push({
        name: 'documentsDetail',
        params: {
          workspace_id: this.$route.params.workspace_id,
          contract_id: this.contract.main_contract_id || this.contract.id,
        },
      });
    },
    redirectToUnfinishedDocument() {
      if (this.getNextUndoneContractId || this.getNextUndoneTemplateId) {
        return this.$router.push({
          name: 'createContent',
          params: {
            workspace_id: this.$route.params.workspace_id,
            contract_id: this.getNextUndoneContractId || this.getNextUndoneTemplateId,
          },
        });
      }
    },
    send(signMethod) {
      if (this.confirmBeforeSend) {
        return this.confirmBeforeSend(signMethod);
      } else {
        return signMethod();
      }
    },
    async sendContracts() {
      try {
        await authorizedApiRequest({
          method: 'POST',
          endpoint: `/api/v1/docs/${this.contract.docs_id}/send`,
        });

        await internalApiRequest({
          method: 'POST',
          path: 'api/v2/contract/batch/pending/send',
          data: {
            contractIds: this.contractsIdsList,
            contactIds: this.contactsIdsForContracts || null,
          },
        });

        this.contactsStore.resetSavedContactsIds();
      } catch (err) {
        throw new Error(err);
      }
    },
    async sendToSpecialSignProcess() {
      this.isSending = true;

      this.sendContracts()
        .then(() => {
          this.$notification.success(this.$t('contract.sent_to_signature'));

          this.$router.push({
            name: 'documentsDetail',
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.contract.main_contract_id || this.contract.id,
            },
          });
        })
        .catch((err) => {
          ContractService.redrawContractAfterSendIfNotDraft(this.contract, false);

          if (err.errorCode === 'Signi.Exceptions.Contract.ContractLackCreditException') {
            setTimeout(() => {
              this.$router.push({ name: 'subscription' });
            }, 800);
          }

          this.$notification.error(getErrorMessage({ errorCode: err.errorCode }));
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    async sendToAmlVerification() {
      this.isSending = true;

      return authorizedApiRequest({
        method: 'PATCH',
        endpoint: `/api/v1/docs/${this.contract.main_contract_id || this.contract.id}/sendAuths`,
      })
        .then(() => {
          this.$notification.success(this.$t('contract.sent_to_signature'));

          this.sendContracts().then(() => {
            this.$router.push({
              name: 'contractsCollectionCreated',
              params: {
                contractId: this.contract.main_contract_id || this.contract.id,
                workspaceId: this.$route.params.workspace_id,
              },
            });
          });
        })
        .catch((err) => {
          if (err.errorCode === 'Signi.Exceptions.Contract.ContractLackCreditException') {
            setTimeout(() => {
              this.$router.push({ name: 'subscription' });
            }, 800);
          }

          this.$notification.error(getErrorMessage({ errorCode: err.errorCode }));
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    sendContract({ request, flashMessage }) {
      if (this.isSending) {
        return;
      }

      this.isSending = true;

      this.sendContracts()
        .then(() => {
          ContractService.detail(this.$route.params.contract_id, this.$route.params.workspace_id).then((resp) => {
            this.$store.commit('setContract', resp);

            if (request && request.sign_now) {
              if (resp?.current_user?.can_stamp) {
                return WorkflowHelper.clickOnStampLink({
                  contract: resp,
                  routes: {
                    params: {
                      workspace_id: this.$route.params.workspace_id,
                      contract_id: this.$route.params.contract_id,
                    },
                    query: this.$route.query.progress ? { progress: 1 } : null,
                  },
                  signStamp: () =>
                    approveContractAsRegisteredUser({
                      contractId: resp.id,
                      signIdentityId: resp?.current_sign_identity?.id,
                      note: null,
                    }),
                  redirectToCompleted: () =>
                    this.$router.push({
                      name: 'contractsCollectionCreated',
                      params: {
                        contractId: this.$route.params.contract_id,
                        workspaceId: this.$route.params.workspace_id,
                      },
                    }),
                });
              }

              WorkflowHelper.clickOnSignLink({
                contract: resp,
                routes: {
                  params: {
                    workspace_id: this.$route.params.workspace_id,
                    contract_id: this.$route.params.contract_id,
                  },
                  query: { progress: 1 },
                },
              });
            } else {
              if (resp.one_device) {
                return this.$router.push({
                  name: 'documentsDetailCounterparty',
                  params: {
                    workspace_id: this.$route.params.workspace_id,
                    contract_id: resp.main_contract_id || resp.id,
                  },
                  query: {
                    viewContract: true,
                    complete: true,
                  },
                });
              }

              this.$notification.success(flashMessage || this.$t('contract.sent_to_signature'));
              this.$router.push({
                name: 'contractsCollectionCreated',
                params: {
                  contractId: resp.id,
                  workspaceId: this.$route.params.workspace_id,
                },
              });
            }
          });
        })
        .catch((err) => {
          ContractService.redrawContractAfterSendIfNotDraft(this.contract, false);

          this.$notification.error(getErrorResponseMessage(err?.response?.data));
          this.$notification.error(this.$t('general.save_error'));
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    sendDocumentWithSign() {
      this.sendContract({
        request: {
          sign_now: 1,
        },
      });
    },
    sendDocumentWithoutSignAsParty() {
      this.sendContract({});
    },
    sendDocumentWithoutSignToReview() {
      this.sendContract({
        request: {
          state: 'review',
        },
        flashMessage: this.$t('review.sent_to_reviewer'),
      });
    },
    sendDocumentWithoutSignToContacts() {
      this.sendContract({});
    },
    sendDocumentWithoutSignAsAssistant() {
      this.sendContract({});
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

// former ContentFinishedFull.vue for doc/wysiwyg
.content-finished-full {
  padding: 20px;

  &__credits {
    background-color: #6decaf;
    border-radius: 50px;
    font-size: 0.6rem;
  }

  @include md {
    padding: 10px 10px 0;
  }
}

.content__buttons {
  &--add-attachment {
    cursor: pointer;

    @include display(960px) {
      border-top: 1px solid #d8d8d8;
      margin: 1rem -1rem 0.7rem -1rem;
      padding: 1rem 1rem 0 1rem;
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }

    &-icon {
      background: #f1f3fb;
      border-radius: 6px;
    }
  }
}

.content-finished-full__title {
  margin-top: 20px;
}

.content-finished-full__desc {
  margin-top: 8px;
  p {
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 25px;
    font-weight: 300;
  }
}

.content-finished-full__buttons {
  margin-top: 20px;
}

.content-finished-full__ico {
  float: left;
  width: 33.3334%;

  @include md {
    width: 100%;
    float: none;
  }
}

.content-finished-full__content {
  float: left;
  width: 33.3334%;

  @include md {
    width: 100%;
    float: none;
  }
}

.content-finished-full__buttons {
  float: left;
  width: 33.3334%;

  @include md {
    width: 100%;
    float: none;
  }
}

// former ContentFinished.vue for template contracts
.content-finished {
  padding: 20px 15px;
  background-color: #fff;
  @include md {
    padding: 20px 15px 0;
  }
}

@media screen and (min-width: 992px) {
  .content-finished {
    display: block !important;
  }
}

.content-finished__close__wrapper {
  @include md {
    text-align: left;
    margin-top: 30px;
  }
}

.content-finished__close {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 600;

  @include md {
    position: static;
    display: block;
  }
}

.content-finished__draft {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 600;

  @include md {
    position: static;
    display: block;
    margin-top: 15px;
  }
}

.content-finished__title {
  margin-top: 20px;
}

.content-finished__desc {
  margin-top: 8px;
  p {
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 25px;
    font-weight: 300;
  }
}

.content-finished__buttons {
  margin-top: 20px;
}
.contract-bottom-content--right {
  width: 70%;
  @include md {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    button {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .button--decline {
      span {
        display: flex;
      }
    }
  }
}
.contract-bottom-content--left {
  width: 30%;
  @include md {
    width: 100%;
  }
}

.contract-bottom-content-item {
  font-size: 15px;
  letter-spacing: -0.03px;
  color: #000000;
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  cursor: pointer;

  @include md {
    border-top: 1px solid #d8d8d8;
    margin: 16px -15px 10px -15px;
    padding: 15px 15px 0 15px;
  }

  &:hover {
    text-decoration: none;

    strong {
      text-decoration: none;
    }
  }

  strong {
    font-weight: 500;
    margin-right: 5px;
    text-decoration: underline;
  }

  span {
    font-weight: 400;

    @include md {
      display: block;
    }
  }

  svg {
    margin-right: 10px;
  }
}
.contract-bottom-content-item-icon {
  height: 35px;
  background: #f1f3fb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
  width: 35px;
  svg {
    margin: 0;
  }
}
.contract-bottom-content-item-secondary {
  padding-top: 5px;
  color: gray;
}
.content-box__header__start-over {
  text-decoration: underline;
  cursor: pointer;
  letter-spacing: initial;
  text-transform: initial;
  padding: 10px 40px;
  font-size: 13px;
  width: 100%;
  color: #9ca2ad;
  top: -5px;
  text-align: right;
  border-bottom: 1px solid #dfe7f3;
  position: relative;
}

.contract-bottom-content--inconsistent {
  color: red;
  font-weight: bold;
  text-decoration: underline;
  font-size: 18px;
}
</style>
