<script setup>
import signiIcon from '@/app/assets/logos/signi_icon.svg';
import { useRoute, useRouter } from 'vue-router/composables';
import { classify } from 'tailwind-classify';

const route = useRoute();
const router = useRouter();

function onClose() {
  router.push({
    name: 'dashboard',
    params: {
      workspace_id: route.params.workspaceId,
    },
  });
}
</script>

<template>
  <header
    :class="
      classify(
        'tw-grid tw-min-h-[63px] tw-grid-cols-3 tw-items-center tw-justify-between tw-px-5',
        'tw-border-b tw-border-b-blue-200 tw-bg-blue-50',
        'md:tw-px-10',
      )
    "
  >
    <div></div>

    <div class="tw-text-center">
      <v-btn
        icon
        @click="
          $router.push({
            name: 'dashboard',
            params: {
              workspace_id: $route.params.workspaceId,
            },
          })
        "
      >
        <v-img
          class="shrink"
          :src="signiIcon"
          transition="scale-transition"
          contain
        />
      </v-btn>
    </div>

    <div class="tw-text-right">
      <v-btn
        class="subtitle-1 font-weight-regular"
        color="primary"
        text
        @click="onClose"
      >
        <span class="tw-hidden md:tw-block">
          {{ $t(`general.save_and_close`) }}
        </span>

        <v-icon
          color="#7031B4"
          size="24"
          :right="!$vuetify.breakpoint.mobile"
        >
          {{ `mdi-${$vuetify.breakpoint.mobile ? 'content-save-outline' : 'close'}` }}
        </v-icon>
      </v-btn>
    </div>
  </header>
</template>

<style scoped lang="scss"></style>
