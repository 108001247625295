import { internalApiRequest } from '@/shared/services/apiService';

export const createInvitation = (workspaceId, data) => {
  return internalApiRequest({
    method: 'POST',
    path: `api/v1/workspaces/${workspaceId}/invitations`,
    data,
  });
};

export const resendMemberInvitation = (workspaceId, invitationId) => {
  return internalApiRequest({
    method: 'POST',
    path: `api/v1/workspaces/${workspaceId}/invitations/${invitationId}/resend`,
  });
};

export const updateWorkspaceMemberInvitationSettings = (workspaceId, invitationId, data) => {
  return internalApiRequest({
    method: 'PUT',
    path: `api/v1/workspaces/${workspaceId}/invitations/${invitationId}`,
    data,
  });
};

export const deleteWorkspaceMemberInvitation = (workspaceId, invitationId) => {
  return internalApiRequest({
    method: 'DELETE',
    path: `api/v1/workspaces/${workspaceId}/invitations/${invitationId}`,
  });
};

export const updateWorkspaceMemberSettings = (workspaceId, memberId, data) => {
  return internalApiRequest({
    method: 'PUT',
    path: `api/v1/workspaces/${workspaceId}/allowedusers/${memberId}`,
    data,
  });
};

export const deleteWorkspaceMember = (workspaceId, memberId) => {
  return internalApiRequest({
    method: 'DELETE',
    path: `api/v1/workspaces/${workspaceId}/allowedusers/${memberId}`,
  });
};
