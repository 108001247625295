<script setup>
import { computed, getCurrentInstance, nextTick, onMounted, ref } from 'vue';

const props = defineProps({
  stateType: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    default: 'fit',
  },
});
const emits = defineEmits(['stateColor']);

const app = getCurrentInstance();

const dashboardState = ref(null);

const currentState = computed(() => {
  return states.value[props.stateType];
});

const states = computed(() => ({
  draft: {
    icon: 'mdi-alert',
    color: 'grey',
    title: app.proxy.$t('docs.drafts'),
  },
  pending: {
    icon: 'mdi-fountain-pen-tip',
    color: 'blue',
    title: app.proxy.$t('docs.pending.generic'),
  },
  'pending.approve': {
    icon: 'mdi-account-clock',
    color: 'blue',
    title: app.proxy.$t('docs.pending.approve'),
  },
  'pending.me': {
    icon: 'mdi-fountain-pen-tip',
    color: 'blue',
    title: app.proxy.$t('docs.pending.me'),
  },
  'pending.not_me': {
    icon: 'mdi-fountain-pen-tip',
    color: 'blue',
    title: app.proxy.$t('docs.pending.not_me'),
  },
  'pending.expiring': {
    icon: 'mdi-clock-alert',
    color: 'yellow',
    title: app.proxy.$t('docs.pending.expiring'),
  },
  rejected: {
    icon: 'mdi-close-box',
    color: 'red',
    title: app.proxy.$t('docs.rejected'),
  },
  expired: {
    icon: 'mdi-calendar-remove',
    color: 'red',
    title: app.proxy.$t('docs.expired'),
  },
  completed: {
    icon: 'mdi-check-circle',
    color: 'green',
    title: app.proxy.$t('docs.completed'),
  },
  'completed.expiring': {
    icon: 'mdi-clipboard-text-clock',
    color: 'yellow',
    title: app.proxy.$t('docs.completed.expiring'),
  },
  'done.approve': {
    icon: 'mdi-check-circle',
    color: 'green',
    title: app.proxy.$t('general.approved'),
  },
  'done.sign': {
    icon: 'mdi-check-circle',
    color: 'green',
    title: app.proxy.$t('signing.signed'),
  },
}));

onMounted(() => {
  nextTick(() => {
    if (dashboardState.value) {
      const style = getComputedStyle(dashboardState.value);

      if (style) {
        emits('stateColor', style.getPropertyValue('color'));
      }
    }
  });
});
</script>

<template>
  <div
    class="dashboard-state d-flex"
    :class="`dashboard-state__width-${width}`"
  >
    <div
      ref="dashboardState"
      :class="`dashboard-state-item__${currentState.color}
      dashboard-state-item__${currentState.color}--background`"
      class="d-flex align-center dashboard-state-item"
    >
      <v-icon
        :class="`dashboard-state-item__${currentState.color}`"
        class="dashboard-state-item__icon align-self-start"
        small
      >
        {{ currentState.icon }}
      </v-icon>

      <span>{{ currentState.title }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dashboard-state__width {
  &-fit {
    width: fit-content;
  }
  &-full {
    width: 100%;
  }
}

.dashboard-state {
  padding-bottom: 10px;
  font-size: 0.75rem;
  line-height: 15px;
  font-weight: bold;
  text-transform: uppercase;

  .dashboard-state-item {
    padding: 4px;
    border-radius: 6px;

    &__icon {
      padding-right: 4px;
    }

    &__yellow {
      color: #db9f10 !important;

      &--background {
        background-color: #fff7d0;
        border: 1px solid #ffe173;
      }
    }

    &__red {
      color: #db465d !important;

      &--background {
        background-color: #ffe8df;
        border: 1px solid #ffaa9f;
      }
    }

    &__blue {
      color: #1367d4 !important;

      &--background {
        background-color: #d1effe;
        border: 1px solid #75c3fc;
      }
    }

    &__green {
      color: #24831d !important;

      &--background {
        background-color: #eafbd9;
        border: 1px solid #ace989;
      }
    }

    &__grey {
      color: #6c87bb !important;

      &--background {
        background-color: #edf5fd;
        border: 1px solid #c5daf3;
      }
    }
  }
}
</style>
