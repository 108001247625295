/**
 * Checks if a specific feature flag is enabled for an entity
 *
 * @param {Object} entity - The entity to check feature flags for
 * @param {string} [featureFlag] - The specific feature flag to check
 * @returns {boolean} Whether the feature flag is enabled or if any flags exist when no specific flag is provided
 */
export function hasFeatureFlag(entity, featureFlag) {
  if (entity?.feature_flags === undefined) {
    return false;
  }

  if ('' === featureFlag) {
    return 0 !== Object.keys(entity.feature_flags).length;
  }

  if (Array.isArray(entity.feature_flags)) {
    return entity.feature_flags.includes(featureFlag) || false;
  }

  return entity.feature_flags[featureFlag] || false;
}
