import { apiClient } from '@/app/api';

export const BulkOperationType = Object.freeze({
  MOVE: 'move',
  DELETE: 'delete',
  DELETE_PDF: 'delete_pdf',
  PROLONG_EXPIRATION: 'prolong_expiration',
  SIGN: 'sign',
  CHANGE_PROPOSER: 'change_proposer',
  NOTIFICATION_SIGN: 'notification_sign',
});

/**
 * Perform move operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 * @param {number} folderId
 */
export function performBulkDocumentsOperationMove(workspaceId, docsIds, folderId) {
  return performBulkDocumentsOperation({
    operation: BulkOperationType.MOVE,
    workspaceId,
    docsIds,
    options: { folderId },
  });
}

/**
 * Perform sign operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 */
export function performBulkDocumentsOperationSign(workspaceId, docsIds) {
  return performBulkDocumentsOperation({ operation: BulkOperationType.SIGN, workspaceId, docsIds });
}

/**
 * Perform delete operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 */
export function performBulkDocumentsOperationDelete(workspaceId, docsIds) {
  return performBulkDocumentsOperation({ operation: BulkOperationType.DELETE, workspaceId, docsIds });
}

/**
 * Perform delete pdf operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 */
export function performBulkDocumentsOperationDeletePdf(workspaceId, docsIds) {
  return performBulkDocumentsOperation({ operation: BulkOperationType.DELETE_PDF, workspaceId, docsIds });
}

/**
 * Perform prolong expiration operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 * @param {number} extraDaysCount
 */
export function performBulkDocumentsOperationProlongExpiration(workspaceId, docsIds, extraDaysCount) {
  return performBulkDocumentsOperation({
    operation: BulkOperationType.PROLONG_EXPIRATION,
    workspaceId,
    docsIds,
    options: { extraDaysCount },
  });
}

/**
 * Perform change proposer operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 * @param {number} contactId
 */
export function performBulkDocumentsOperationChangeProposer(workspaceId, docsIds, userId) {
  return performBulkDocumentsOperation({
    operation: BulkOperationType.CHANGE_PROPOSER,
    workspaceId,
    docsIds,
    options: { userId },
  });
}

/**
 * Perform notify sing operation on documents
 * @param {number} workspaceId
 * @param {number[]} docsIds
 */
export function performBulkDocumentsOperationNotifySign(workspaceId, docsIds) {
  return performBulkDocumentsOperation({ operation: BulkOperationType.NOTIFICATION_SIGN, workspaceId, docsIds });
}

/**
 * Perform bulk operation on documents
 * @param payload
 * @returns {Promise<AxiosResponse<>>}
 */
export function performBulkDocumentsOperation(payload) {
  return apiClient.post('/api/v1/docs-batch', payload);
}
