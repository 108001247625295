export const SUPER_OVERVIEW_ROUTES = {
  SUPER_OVERVIEW_ROUTE: 'dashboard--super-overview',
};

export default [
  {
    path: '/dashboard/documents/overview',
    name: SUPER_OVERVIEW_ROUTES.SUPER_OVERVIEW_ROUTE,
    component: () => import('@/app/modules/dashboard/features/superOverview/views/SuperOverview.vue'),
    meta: {
      authRequired: true,
      fullLayout: false,
    },
  },
];
