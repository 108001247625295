<script setup>
import { ref } from 'vue';
import SDatePicker from '@/common/components/SDatePicker.vue';

const props = defineProps({
  isSchedulingCampaign: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['schedule-campaign', 'cancel']);

const selectedDate = ref(new Date().toISOString());

function onScheduleCampaign() {
  emit('schedule-campaign', selectedDate.value);
}

function onCloseDialog() {
  emit('cancel');
}
</script>

<template>
  <v-dialog
    :value="true"
    max-width="500px"
  >
    <div class="tw-flex tw-flex-col tw-gap-y-6 tw-bg-white tw-p-6">
      <div class="tw-flex tw-items-center tw-justify-end">
        <v-btn
          icon
          @click="onCloseDialog"
        >
          <v-icon color="#868E96">mdi-close</v-icon>
        </v-btn>
      </div>

      <h2 class="tw-text-center tw-font-bold tw-text-[#424242]">{{ $t('campaign.schedule_send') }}</h2>

      <div>
        <SDatePicker
          v-model="selectedDate"
          :label="`${$t('contract.date')}`"
          :future-only="true"
        />
      </div>

      <div class="tw-h-px tw-w-full tw-bg-[#D4DFF0]"></div>

      <div class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-4 md:tw-flex-row">
        <v-btn
          class="tw-order-last md:tw-order-none"
          text
          :block="$vuetify.breakpoint.mobile"
          @click="onCloseDialog"
        >
          <v-icon left>mdi-close</v-icon>
          {{ $t('general.cancel') }}
        </v-btn>

        <v-btn
          color="primary"
          :block="$vuetify.breakpoint.mobile"
          :loading="props.isSchedulingCampaign"
          @click="onScheduleCampaign"
        >
          {{ $t('general.schedule_send') }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
