<template>
  <div class="input-search">
    <div
      v-if="isEditForm"
      @click="onToggleClick"
    >
      <v-text-field
        v-model="label"
        flat
        hide-details
        readonly
      >
        <template v-slot:append>
          <s-help v-if="!variableUsersEnabled">
            <template v-slot:content>
              <p
                class="mb-0"
                v-html="$t('workspace.members.info.variable_users')"
              ></p>
            </template>
          </s-help>

          <v-icon>
            {{ openSearchId === currentId ? 'mdi-menu-up' : 'mdi-menu-down' }}
          </v-icon>
        </template>
      </v-text-field>
    </div>

    <v-card
      v-else
      outlined
      class="input-search--toggle d-flex align-center justify-space-between pr-4"
      :class="{ 'input-search--toggle--open': openSearchId === currentId }"
      :style="'position' === type && !$vuetify.breakpoint.mobile ? 'border: none !important' : ''"
      @click="onToggleClick"
    >
      <v-card-subtitle class="pl-3">
        {{ label }}
      </v-card-subtitle>

      <div>
        <s-help v-if="!variableUsersEnabled">
          <template v-slot:content>
            <p
              class="mb-0"
              v-html="$t('workspace.members.info.variable_users')"
            ></p>
          </template>
        </s-help>

        <v-icon>
          {{ openSearchId === currentId ? 'mdi-menu-up' : 'mdi-menu-down' }}
        </v-icon>
      </div>
    </v-card>

    <div
      v-if="openSearchId === currentId"
      class="input-search--content"
    >
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        ref="searchInput"
        v-model="searchTerm"
        :hint="hint"
        persistent-hint
        flat
        solo
        single-line
        :hide-details="!hint"
      >
        <template v-slot:message="{ message }">
          <p
            v-html="message"
            class="ma-0 subtitle-2 font-weight-regular"
          ></p>
        </template>
      </v-text-field>

      <template v-if="!debounceTimeout">
        <v-card
          v-if="positions.length"
          class="input-search--results-wrapper"
        >
          <v-list class="py-0">
            <v-list-item-group class="pl-0">
              <v-list-item
                v-for="position in availablePosition"
                :key="position.id"
                @click="onSignerSelect(position)"
                three-line
              >
                <v-list-item-icon>
                  <VerificationBadge
                    :avatar-size="30"
                    :contact="position"
                  />
                </v-list-item-icon>

                <v-list-item-content style="text-align: left">
                  <v-list-item-title v-if="position.name">
                    {{ position.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

        <v-card
          v-if="searchTerm.length >= 3"
          class="input-search--no-results"
        >
          <div
            v-if="!positions.length"
            class="color-light-gray"
          >
            <template v-if="type === 'position'">
              <v-card-text class="d-flex align-center flex-column pb-0">
                <p>{{ $t('contract.search.position_not_found') }}</p>
              </v-card-text>
            </template>
          </div>
          <v-card-actions>
            <v-btn
              color="primary"
              plain
              text
              x-large
              @click="onSignerSelect"
            >
              {{ `${$t('contract.search.create_position')} ${searchTerm}` }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hasFeatureFlag } from '@/app/common/utils/features';
import VerificationBadge from '@/features/contacts/components/VerificationBadge';

export default {
  name: 'SPositionSearch',
  components: {
    VerificationBadge,
  },
  props: {
    positions: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      required: false,
    },
    openSearchId: {
      required: false,
      default: null,
    },
    currentId: {
      required: true,
    },
    isDynamicList: {
      type: Boolean,
      required: true,
    },
    isEditForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      debounceTimeout: 0,
    };
  },
  computed: {
    ...mapGetters({
      workspaceById: 'workspaceById',
    }),
    availablePosition() {
      return [
        {
          name: '',
        },
        ...this.positions,
      ];
    },
    currentWorkspace() {
      return this.workspaceById(this.$route?.params?.workspace_id);
    },
    variableUsersEnabled() {
      return hasFeatureFlag(this.currentWorkspace, 'variableUsersEnabled');
    },
  },
  watch: {
    searchTerm() {
      if (!this.isDynamicList) {
        return this.$emit('onSearchTermChange', this.searchTerm);
      }
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.$emit('onSearchTermChange', this.searchTerm);
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = false;
      }, 500);
    },
    openSearchId() {
      this.searchTerm = '';
    },
  },
  methods: {
    onSignerSelect(id) {
      this.$emit('onSignerSelect', this.searchTerm || id.name);
      this.$emit('toggleSearch', null);
    },
    onToggleClick() {
      if (this.variableUsersEnabled) {
        if (this.currentId === this.openSearchId) {
          this.$emit('toggleSearch', null);
        } else {
          this.$emit('toggleSearch', this.currentId);
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-search {
  position: relative;
}
.input-search--toggle {
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 6px;
  border: 1px solid #c5ccd8 !important;

  &--open {
    border-radius: 6px 6px 0 0 !important;
  }
}

.v-list-item--three-line {
  min-height: auto;
}

.v-card__subtitle {
  font-size: 1rem;
}

.input-search--content {
  position: absolute;
  width: 100%;
  z-index: 1000000000;
  background: white;
  min-width: 250px;
  border: 1px solid #c5ccd8;
}

.input-search--no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: scroll;
}

.color-light-gray {
  color: #9fa6b1;
}

.input-search--results-wrapper {
  max-height: 150px;
  overflow: auto;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1250875350140056) 0%,
    rgba(0, 0, 0, 0) 8%,
    rgba(255, 255, 255, 0) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>
